/* eslint-disable @next/next/no-img-element */
import { useMediaQuery } from '@hooks';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const collabs = {
  others: ['/collaborators/mcs.svg', '/collaborators/mcs-cola.svg', '/collaborators/mezorn.svg', '/collaborators/mcs-property.svg', '/collaborators/mcs-international.svg', '/collaborators/mplus.svg'],
  tablet: ['/collaborators/mcs.svg', '/collaborators/mcs-cola.svg', '/collaborators/mezorn.svg', '/collaborators/mcs-property.svg', '/collaborators/mcs-international.svg', '/collaborators/mplus.svg'],
};

export const HomeCollaboratorsSection = () => {
  const isDesktop = useMediaQuery('lg');
  const isTablet = useMediaQuery('md');
  const { spacing } = useTheme();

  return (
    <Stack bgcolor="text.primary" width="100vw" paddingY={{ lg: 11, md: 8, sm: 7 }} alignItems="center">
      <Stack direction={{ sm: 'column', md: 'row' }} alignItems="top" width={{ sm: '100%', md: 720, lg: 1440 }} paddingX={{ sm: 3, lg: 8 }}>
        <Stack direction="column" flex={1} justifyContent="center" paddingBottom={{ lg: 12 }}>
          <Typography lineHeight="50%" fontSize={{ lg: '62px', md: '36px', sm: '31px' }} textTransform="uppercase" fontWeight={700} sx={{ transform: 'scaleY(0.76)' }} color="primary">
            _
          </Typography>
          <Typography lineHeight="110%" fontSize={{ lg: '62px', md: '36px', sm: '31px' }} textTransform="uppercase" fontWeight={700} sx={{ transform: 'scaleY(0.76)' }} color="white">
            хамтран
            <br />
            ажилладаг
            <br />
            байгууллагууд
          </Typography>
          <Typography color="white" variant={isDesktop ? 'bodyM' : 'bodyS'}>
            СБД, 1-р хороо, Чингисийн өргөн чөлөө, Гурван гол оффис центр 3 давхар
          </Typography>
        </Stack>

        <Stack direction="row" flex={1} flexWrap="wrap" gap={{ sm: 2, md: 4, lg: 0 }} justifyContent="space-between" alignItems="center" mt={{ sm: 4, md: 0 }}>
          {collabs['tablet'].map((collab, i) => (
            <img
              key={`collaborators-${i}`}
              src={collab}
              alt={collab}
              style={{
                height: isDesktop ? spacing(6) : spacing(3),
                width: 'auto',
              }}
              data-cy="Home-Collab-Images"
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

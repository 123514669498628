import { PineconeFilledLogo, PineconeLogoLeft } from '@assets/icons';
import { useGetEnrollmentProgramDetailsQuery } from '@generated';
import { Box, Button, Stack, Typography } from '@mui/material';
import Link from 'next/link';
import { useMemo } from 'react';
const endDate = '2023-06-05T00:00:00.000Z';
const programName = 'HOP_PROGRAM';

export const HomeJourneySection = () => {
  const { data } = useGetEnrollmentProgramDetailsQuery();
  const daysLeft = useMemo(() => {
    const diff = new Date(endDate).getTime() - Date.now();
    const dayLeft = Math.ceil(diff / (1000 * 3600 * 24));
    return dayLeft.toString();
  }, []);

  const totalLeftSeats = data?.getEnrollmentProgramDetails?.find(({ program }) => program === programName)?.result?.reduce((prev, { leftSeatsCount }) => prev + Number(leftSeatsCount), 0) ?? 0;

  return (
    <Stack bgcolor={({ palette }) => palette.text.primary} width="100%" overflow="hidden" alignItems="center">
      <Stack direction={{ sm: 'column', md: 'row' }} justifyContent="space-around" width={{ sm: '100%', md: 720, lg: 1440 }} paddingY={10} paddingX={3} gap={4} position={'relative'}>
        <Box>
          <Typography color="white" fontWeight={590} fontSize={'13px'} lineHeight={'16x'}>
            <span style={{ color: '#1AC74A', marginRight: '8px' }}>{`>`}</span>
            Зуны ангийн
          </Typography>
          <Title dataCy="Home-Journey-Title">
            Аялалаа
            <br />
            эхлэх үү?
          </Title>
        </Box>
        <Box
          position="absolute"
          display={{
            sm: 'flex',
            md: 'none',
          }}
          sx={{
            top: 0,
            right: 0,
            height: '100%',
            paddingY: '24px',
          }}
        >
          <PineconeLogoLeft color="rgba(255, 255, 255, 0.12)" height={'368px'} width={'149px'} />
        </Box>
        <Stack alignItems={{ sm: 'flex-start', md: 'center' }} justifyContent="center" gap="40px" position="relative">
          <Box
            position="absolute"
            display={{ sm: 'none', md: 'flex' }}
            sx={{
              transform: {
                md: 'scale(2) rotate(20deg)',
                lg: 'scale(2) rotate(28deg)',
              },
              top: 'auto',
              right: 'auto',
            }}
          >
            <PineconeFilledLogo width="426px" height="426px" color="rgba(255, 255, 255, 0.12)" />
          </Box>

          <Stack direction="row" gap={{ sm: 2, md: 5 }}>
            {/* <Stack alignItems="center" direction={{ sm: 'row', md: 'column' }} gap={{ sm: 1.5, md: 0 }}>
              <Title>{daysLeft}</Title>
              <Typography color="white" variant="bodyM" width={{ sm: 'min-content', md: 'fit-content' }} lineHeight={'16px'}>
                Өдөр үлдсэн
              </Typography>
            </Stack> */}

            {/* <Box width={1.5} height={{ sm: 40, md: '100%' }} bgcolor="white" sx={{ opacity: 0.3 }} /> */}

            <Stack alignItems="center" direction={{ sm: 'row', md: 'column' }} gap={{ sm: 1.5, md: 0 }}>
              <Title dataCy="Home-Journey-Number-Of-Seats">{totalLeftSeats.toString()}</Title>
              <Typography color="white" variant="bodyM" width={{ sm: 'min-content', md: 'fit-content' }} lineHeight={'16px'}>
                Суудал үлдсэн
              </Typography>
            </Stack>
          </Stack>
          <Link href="/enroll/">
            <Button variant="contained" data-cy="Home-Journey-Enroll-Button">
              <Typography color="text.primary" variant="buttonM" mx={6} data-cy="Journey-Register-For-Class-Button">
                Элсэх
              </Typography>
            </Button>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Title = ({ children, dataCy }: { children: (JSX.Element | string)[] | string; dataCy?: string }) => (
  <Typography
    width="fit-content"
    lineHeight={'120%'}
    fontSize={{ lg: '62px', md: '36px', sm: '32px' }}
    textTransform="uppercase"
    fontWeight={540}
    sx={{ transform: 'scaleY(0.76)' }}
    color="white"
    data-cy={dataCy}
  >
    {children}
  </Typography>
);

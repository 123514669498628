import { RoundedArrowLeftIcon, RoundedArrowRightIcon } from '@assets/icons';
import { Box, Stack, Typography } from '@mui/material';
import { motion, useInView } from 'framer-motion';
import Image from 'next/image';
import { useMemo, useRef, useState } from 'react';
import { impression } from './data-content';

export const HomeImpressionSection = () => {
  const [impressionIndex, setImpressionIndex] = useState(0);
  const wordsRef = useRef(null);
  const isInView = useInView(wordsRef);

  const currentImpression = useMemo(() => {
    return impression[impressionIndex];
  }, [impressionIndex]);

  return (
    <Stack alignItems={'center'} justifyContent="center">
      <Stack
        sx={{
          width: {
            sm: '100%',
            md: '599px',
            lg: '832px',
          },
        }}
        textAlign={'center'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography lineHeight={'100%'} flex={1} fontSize={{ lg: '62.4px', md: '36.4px', sm: '31.2px' }} textTransform="uppercase" fontWeight={700} sx={{ transform: 'scaleY(0.76)' }} color="thirtary">
          Сэтгэгдэл
        </Typography>
        <Typography
          ref={wordsRef}
          typography={{ sm: 'impressionS', md: 'impressionM', lg: 'impressionL' }}
          color="#111111"
          minHeight={{ sm: '182px', md: '192px' }}
          sx={{
            margin: {
              lg: '56px 0px',
              sm: '48px 0px',
            },
          }}
          data-cy="Home-Impression-Text"
        >
          {isInView &&
            currentImpression.text.split(' ').map((word, index) => (
              <motion.span
                key={word + index + impressionIndex}
                initial={{ opacity: 0 }}
                animate={{ opacity: isInView ? 1 : 0 }}
                transition={{
                  delay: index * 0.05,
                }}
                data-cy="Home-Impression-Motion"
              >
                {`${word} `}
              </motion.span>
            ))}
        </Typography>

        <Stack direction={'row'} width={'100%'} padding={{ sm: '0px', md: '0px 48px' }}>
          <Stack direction={'row'} gap="16px" flex={1}>
            <Box
              sx={{
                width: {
                  sm: '40px',
                  md: '56px',
                  lg: '72px',
                },
                height: {
                  sm: '40px',
                  md: '56px',
                  lg: '72px',
                },
                borderRadius: '50%',
              }}
            >
              <Image src={currentImpression.profileImage} fill alt={currentImpression.name} />
            </Box>
            <Stack direction={'column'} gap={{ sm: '4px', md: '8px' }} justifyContent={'flex-start'} textAlign="start">
              <Typography
                fontSize={{
                  sm: '16px',
                  md: '18px',
                  lg: '20px',
                }}
                fontWeight={600}
              >
                {currentImpression.name}
              </Typography>
              <Typography
                fontSize={{
                  sm: '13px',
                  md: '14px',
                  lg: '16px',
                }}
                fontWeight={400}
              >
                {currentImpression.label}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" gap="20px">
            <Box
              sx={{ cursor: 'pointer', opacity: impressionIndex === 0 ? 0.2 : 1 }}
              onClick={() => setImpressionIndex(impressionIndex == 0 ? 0 : impressionIndex - 1)}
              data-cy="Home-Impression-Left-Button"
            >
              <RoundedArrowLeftIcon />
            </Box>
            <Box
              sx={{ cursor: 'pointer', opacity: impressionIndex === impression.length - 1 ? 0.2 : 1 }}
              onClick={() => setImpressionIndex(impressionIndex == impression.length - 1 ? impressionIndex : impressionIndex + 1)}
              data-cy="Home-Impression-Right-Button"
            >
              <RoundedArrowRightIcon />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

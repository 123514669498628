import { Box } from '@mui/material';
import { ChevronImage } from '@subsets/about-us/chevron';

export interface HomePageGalleryContentProps {
  label: string;
  image: string;
  shape: 'inner' | 'outer';
}
export const HomePageGalleryContent: HomePageGalleryContentProps[] = [
  {
    label: 'ТУРШЛАГАД СУУРИЛСАН СУРГАЛТ',
    image: '/gallery/comfortable.webp',
    shape: 'inner',
  },
  {
    label: 'ТАВ ТУХТАЙ ОРЧИН',
    image: '/gallery/home-gallery-office.webp',
    shape: 'outer',
  },
  {
    label: 'СУРАЛЦАГЧ БҮРТ ХҮРЭХ ХӨТӨЛБӨР',
    image: '/gallery/home-gallery-1.webp',
    shape: 'inner',
  },
];

export const HomeEnvironmentSection = () => {
  return (
    <Box
      width="100vw"
      sx={{
        '* + *': { mt: -10 },
      }}
    >
      {HomePageGalleryContent.map((cur, index) => (
        <ChevronImage key={index} {...cur} />
      ))}
    </Box>
  );
  // return (
  // <Stack
  //   width="100%"
  //   height={{
  //     lg: 621,
  //     md: 340,
  //     sm: 428,
  //   }}
  //   alignItems="center"
  //   justifyContent="center"
  // >

  //   {/* <Box height="100%" width="100%" position="absolute" top={0} left={0}>
  //     <Image
  //       src="/gallery/comfortable.webp"
  //       alt="comfortable"
  //       fill
  //       style={{
  //         objectFit: 'cover',
  //       }}
  //     />
  //   </Box>

  //   <SlideUpOnScroll>
  //     <Typography flex={1} fontSize={{ lg: '62px', md: '36px', sm: '31px' }} textTransform="uppercase" fontWeight={700} sx={{ transform: 'scaleY(0.76)' }} textAlign="center" color="white">
  //       ТАВ тухтай орчин
  //     </Typography>
  //   </SlideUpOnScroll> */}
  // </Stack>
  // );
};

// export const HomeEnvironmentSection = () => {
//   return (
//     <Stack
//       width="100%"
//       height={{
//         lg: 621,
//         md: 340,
//         sm: 428,
//       }}
//       alignItems="center"
//       justifyContent="center"
//     >
//       <Box height="100%" width="100%" position="absolute" top={0} left={0}>
//         <Image
//           src="/gallery/comfortable.webp"
//           alt="comfortable"
//           fill
//           style={{
//             objectFit: 'cover',
//           }}
//         />
//       </Box>

//       <SlideUpOnScroll>
//         <Typography flex={1} fontSize={{ lg: '62px', md: '36px', sm: '31px' }} textTransform="uppercase" fontWeight={700} sx={{ transform: 'scaleY(0.76)' }} textAlign="center" color="white">
//           ТАВ тухтай орчин
//         </Typography>
//       </SlideUpOnScroll>
//     </Stack>
//   );
// };

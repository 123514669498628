import { useMediaQuery } from '@hooks';
import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import Link from 'next/link';

export const HomeProgramSection = () => {
  return (
    <Stack direction={{ sm: 'column', md: 'row' }} gap={2} justifyContent={'space-between'} width="100%">
      <ProgramCard label="HOP" desc="13-18 нас" url="/gallery/hop-program-back.webp" link="/hop" />
      <ProgramCard label="LEAP" desc="18-аас дээш насныхан" position="right" url="/gallery/leap-program-back.webp" link="/leap" />
    </Stack>
  );
};

const ProgramCard = ({ url, label, desc, position = 'left', link }) => {
  const isMobile = useMediaQuery('sm');
  return (
    <Box width={{ sm: '75%', md: '100%' }} height={{ sm: '279px', md: '515px' }} alignSelf={position == 'left' ? 'flex-start' : 'flex-end'} data-cy="Home-Program-Box">
      <Link href={link} data-cy="Home-Program-Link">
        <Stack
          width="100%"
          height="100%"
          className="interactable"
          data-type="link"
          direction="column"
          padding={{ sm: '24px 24px', md: '40px 40px' }}
          justifyContent="flex-end"
          sx={{
            background: `linear-gradient(${position == 'left' ? 90 : 270}deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), url(${url})`,
            WebkitMask: `linear-gradient(45deg, transparent 0 0px ,#fff 0) bottom left,
            linear-gradient( -45deg, transparent 0 ${position == 'right' ? (isMobile ? 24 : 40) : 0}px,#fff 0) bottom right,
            linear-gradient( 135deg, transparent 0 ${position == 'left' ? (isMobile ? 24 : 40) : 0}px,#fff 0) top left,
            linear-gradient(-135deg, transparent 0 0,#fff 0) top right`,
            WebkitMaskSize: `50.1% 50.1%`,
            WebkitMaskRepeat: `no-repeat`,
            cursor: 'pointer',
          }}
          position="relative"
          data-cy="Home-Program-Card"
        >
          <motion.div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundImage: `url(${url})`,
              backgroundSize: `cover`,
              zIndex: 2,
            }}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.4 },
            }}
          >
            <Box
              width="100%"
              height="100%"
              top={0}
              left={0}
              position="absolute"
              zIndex={1}
              sx={{ backgroundImage: `linear-gradient(${position === 'left' ? '90deg' : '270deg'}, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)` }}
            />
          </motion.div>
          <Stack
            zIndex={2}
            gap={0.5}
            alignItems={position === 'left' ? 'start' : 'end'}
            style={{
              pointerEvents: 'none',
            }}
          >
            <Typography variant="displayS" color="white" width="fit-content">
              Хөтөлбөр
            </Typography>
            <Typography textTransform="uppercase" fontSize={{ sm: 45, md: 74, lg: 106 }} lineHeight={'80%'} fontWeight={870} color="white" sx={{ transform: 'scaleY(0.76)' }}>
              {label}
            </Typography>
            <Box width="100%" height={1.5} bgcolor="#FFFFFF" sx={{ opacity: 0.2 }} />
            <Typography variant="displayS" color="white">
              {desc}
            </Typography>
          </Stack>
        </Stack>
      </Link>
    </Box>
  );
};

import { ExpanedTypography, SlideUpOnScroll } from '@components';
import { Stack } from '@mui/material';
import Image from 'next/image';

export function chevronClip(size: number) {
  const inner = `50% ${size}px, 100% 0, 100% 100%, 50% calc(100% - ${size}px), 0 100%, 0% 0%`;
  const outer = `50% 0, 100% ${size}px, 100%  calc(100% - ${size}px), 50% 100%, 0  calc(100% - ${size}px), 0 ${size}px`;
  return { inner, outer };
}

interface ChevronImageProps {
  label?: string;
  image: string;
  shape?: 'inner' | 'outer';
  size?: number;
}

export function ChevronImage({ image, label, size = 56, shape = 'inner' }: ChevronImageProps) {
  const clip = chevronClip(size);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height={{
        sm: 426,
        md: 680,
      }}
      sx={{
        clipPath: `polygon(${clip[shape]})`,
      }}
    >
      <Image
        fill
        alt="img"
        src={image}
        sizes="100% 100%"
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
          backgroundColor: '#F6F6F7',
        }}
      />
      <SlideUpOnScroll>
        <ExpanedTypography color="white" fontSize={{ sm: 24, md: 28, lg: 48 }} textAlign="center" width={{ sm: 250, md: '100%' }} lineHeight="100%">
          {label}
        </ExpanedTypography>
      </SlideUpOnScroll>
    </Stack>
  );
}

export const impression = [
  {
    url: '',
    text: 'Дөнгөж код бичиж эхэлж байхад бүх юм эрээн цоохор л харагддаг байлаа. Одоо тэр цаг үеэ бодоход нэлээн туршлагатай болж хувийн хэд хэдэн төслүүд дээр ажиллаад эхэлжээ.',
    profileImage: '/impressions/tuguldur.png',
    name: 'М.Төгөлдөр',
    label: 'LEAP сурагч',
  },
  {
    url: '',
    text: 'Миний Pinecone академийг сонгосон шалтгаануудын маань нэг бол яах аргагүй манай туршлагатай багш нар юм.',
    profileImage: '/impressions/binderiya.png',
    name: 'Ц.Биндэръяа',
    label: 'LEAP сурагч',
  },
];

export interface INTERVIEW_DATA_PROPS {
  title: string;
  subTitle: string;
  description: string;
  video: string;
  videoPoster: string;
  interviewer?: {
    name: string;
    image: string;
  };
}

export const INTERVIEW_DATA: Array<INTERVIEW_DATA_PROPS> = [
  {
    title: 'Яагаад IT-ын салбар эрэлттэй байна вэ?',
    subTitle: 'Pinecone Academy, LendMN, Clinica, Shoppy.mn, Hippocards, CallPro',
    description: 'IT-ийн компаниуд чадварлаг мэргэжилтэнг байнга хайж байдаг. Технологийн салбар жил бүр 30-40%-аар өсөж байна.',
    video: 'https://youtu.be/jlxNjgTUExA',
    videoPoster: '/other-images/ceo-talk.webp',
  },
  {
    title: 'н. шагай',
    subTitle: 'Pinecone Academy-ын үүсгэн байгуулагч',
    description: '“18 настай хүүхэд ₮2 саяын цалин авах боломжтой өөр салбар ховор”',
    video: 'https://youtu.be/fAu718pYGF0',
    videoPoster: '/video/thumbnail-04.webp',
    interviewer: {
      name: 'Senior Amazon Engineer',
      image: '/other-images/amazon.png',
    },
  },
];

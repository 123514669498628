import { SlideUpOnScroll } from '@components';
import { GetDiscoveryClassListQuery, useGetDiscoveryClassListQuery } from '@generated';
import { useWindowDimensions } from '@hooks';
import { Box, Button, Typography } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useMemo } from 'react';

// Hero Image Mask Angle
const MaskAngle = 4.4473848519;

// Function to generate date string as "M сарын D,D"
const generateDateString = (data: GetDiscoveryClassListQuery) => {
  const month = new Date(data?.getDiscoveryClassList[0]?.classDate).getMonth() + 1;
  const date = data?.getDiscoveryClassList
    .map((item) => new Date(item.classDate).getDate())
    .sort()
    .join(',');

  return `${month} сарын ${date}`;
};

export const HomeHeroDiscoveryClassSection = () => {
  const { data } = useGetDiscoveryClassListQuery();
  const { width, height } = useWindowDimensions();
  const maskPercent = useMemo(() => {
    return (50 * width) / (width / 2 + height / Math.tan(MaskAngle * (Math.PI / 180)));
  }, [width, height]);

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="flex-end"
      justifyContent="center"
      position="relative"
      sx={{
        WebkitMask: [
          `linear-gradient(${MaskAngle}deg, transparent 0%, transparent ${maskPercent}%, white ${maskPercent}%, white 100%) bottom left`,
          `linear-gradient(${180 - MaskAngle}deg, white 0%, white ${100 - maskPercent}%, transparent ${100 - maskPercent}%, transparent 100%) bottom right`,
        ].join(', '),
        WebkitMaskSize: '50% 100%',
        WebkitMaskRepeat: `no-repeat`,
      }}
    >
      {/* Gradient Opacity Black Mask */}

      <Box
        width="100%"
        height="100%"
        position="absolute"
        zIndex={1}
        sx={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 38.09%, rgba(0, 0, 0, 0.7) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))' }}
      />

      {/* Background Image */}

      <Box position="relative" width="100%" height="100%" bgcolor="black" overflow="hidden">
        <Image fill src="/main.webp" alt="main" priority={true} placeholder="blur" blurDataURL="/main.webp" style={{ objectFit: 'cover', objectPosition: '60% 50%' }} />
      </Box>

      {/* Content */}

      <Box
        position="absolute"
        pb={{ sm: 9.5, md: 0 }}
        zIndex={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={{ sm: 'column-reverse', md: 'column' }}
        mb={{ sm: 0, md: 5, lg: 11 }}
      >
        <SlideUpOnScroll>
          <Box display="flex" alignItems="center" justifyContent="center" gap={2} pb={`calc(100vh - ${height})`} mb={{ sm: 14, md: 2 }} mt={{ sm: 2.5, md: 0 }} sx={{ transition: '300ms' }}>
            <Typography fontSize={{ sm: 17, lg: 18 }} lineHeight={{ sm: '22.4px', lg: '28px' }} fontWeight={590} color="primary">
              &gt;
            </Typography>
            <Typography fontSize={{ sm: 16, lg: 20 }} lineHeight={{ sm: '22.4px', lg: '28px' }} fontWeight={590} color="white">
              Цөөн суудал үлдлээ
            </Typography>
            <Typography fontSize={{ sm: 17, lg: 18 }} lineHeight={{ sm: '22.4px', lg: '28px' }} fontWeight={590} color="primary">
              &lt;
            </Typography>
          </Box>
        </SlideUpOnScroll>

        <Box px={1.5}>
          <SlideUpOnScroll>
            <Typography textTransform="uppercase" textAlign="center" fontSize={{ sm: 29, md: 36, lg: 56 }} lineHeight={{ sm: '34.8px', md: '43.2px', lg: '67.2px' }} fontWeight={870} color="primary">
              1 өдрийн үнэгүй
            </Typography>
          </SlideUpOnScroll>

          <SlideUpOnScroll delay={200}>
            <Typography textTransform="uppercase" textAlign="center" fontSize={{ sm: 29, md: 36, lg: 56 }} lineHeight={{ sm: '34.8px', md: '43.2px', lg: '67.2px' }} fontWeight={870} color="white">
              Програмчлалын хичээл
            </Typography>
          </SlideUpOnScroll>
        </Box>

        <Box display={{ sm: 'none', md: 'flex' }} flexDirection="column" justifyContent="center" alignItems="center" gap={2} mt={{ md: 4, lg: 5 }}>
          <Link href="/discovery">
            <Button variant="contained"  data-cy='Hero-Discover-Class-Register-Button'  sx={{ color: 'black', '&:hover': { backgroundColor: 'primary.dark' } }}>
              <Box px={{ md: 2.5, lg: 4 }} >Бүртгүүлэх</Box>
            </Button>
          </Link>

          <Typography sx={{ visibility: data ? 'visible' : 'hidden' }} fontSize={{ md: 16, lg: 20 }} lineHeight={{ md: '24px', lg: '28px' }} color="white" fontWeight={300} data-cy="Hero-Discovery-Class-Date-Text">
            <Typography component="span" fontWeight={590} fontSize="inherit" color="inherit">
              Огноо:
            </Typography>{' '}
            {data && generateDateString(data)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const MobileFixedButton = () => {
  const { data } = useGetDiscoveryClassListQuery();

  return (
    <Box
      position="sticky"
      bottom={0}
      zIndex={9999}
      display={{ sm: 'flex', md: 'none' }}
      width="100vw"
      alignItems="center"
      justifyContent="space-between"
      borderTop={(theme) => '1px solid ' + theme.palette.grey[100]}
      bgcolor="white"
      p={1.5}
    >
      <Box>
        <Typography fontSize={13} lineHeight="16px" fontWeight={590} mb={0.5}>
          Хугацаа
        </Typography>
        <Typography fontSize={13} lineHeight="16px">
          {data && generateDateString(data)}
        </Typography>
      </Box>
      <Link href="/discovery">
        <Button variant="contained" sx={{ '&:hover': { backgroundColor: 'primary.dark' } }}>
          <Box px="26px">Бүртгүүлэх</Box>
        </Button>
      </Link>
    </Box>
  );
};

import { AppearOnScroll, SlideUpByLine, SlideUpOnScroll } from '@components';
import { Box, Stack, Typography } from '@mui/material';
import Image from 'next/image';

const skillsData = [
  {
    title: 'Багаар ажиллах чадвар',
    desc: 'Сурагчид маань багаар ажилласнаар аливаа төслийг багийн хэмжээнд хэрхэн үр ашигтай, өндөр үр дүнтэй, хурдан гүйцэтгэхэд суралцдаг. Мөн энэ нь баг дотор бие биеэсээ суралцах боломжийг нээж өгдөг.',
    image: '/gallery/teamwork.webp',
  },
  {
    title: 'Төсөл хийх чадвар',
    desc: 'Сургалтыг илүү төсөл суурьтай явуулснаар сурагчдад төслийн зорилгыг зөв тодорхойлох, нөөцийг зөв тооцоолох, эрсдэлийн менежмент, хоорондын харилцаа холбоог ойлгох, тодорхойлох гээд чухал ач холбогдолтой байдаг.',
    image: '/gallery/project-making.webp',
  },
  {
    title: 'Үнэлгээ хийх чадвар',
    desc: 'Аливаа төслийг үнэлэх, учирч болох эрсдэлийг тооцоолж сурна. Түүнчлэн дата дээр үндэслэсэн шийдвэр гаргах гээд хөгжүүлэгчид хэрэгтэй хамгийн чухал чадваруудын нэг юм.',
    image: '/gallery/rating.webp',
  },
  {
    title: 'Бие даах чадвар',
    desc: 'Сурагчдад бие даасан байдлыг суулгаснаар төслийг тодорхой хязгаарт баригдалгүйгээр сэтгэж хийх, удирдах, түүний хариуцлагыг ойлгуулах, цагийн менежмент зэрэг чухал чадварыг давхар эзэмшүүлэх юм.',
    image: '/gallery/self-dependence.webp',
  },
];

export const HomeSkillsSection = () => {
  return (
    <Stack width="100%" direction={{ sm: 'column', md: 'row', lg: 'row' }} id="curriculum" marginTop="-120px">
      <Stack flex={1} position="relative" display={{ sm: 'none', md: 'flex', lg: 'flex' }}>
        <Stack position="sticky" top={0} left={0} data-scroll-sticky data-scroll data-scroll-position="top" data-scroll-target="#curriculum" paddingTop="120px">
          <Typography lineHeight={'100%'} fontSize={{ lg: '62.4px', md: '36.4px', sm: '31.2px' }} textTransform="uppercase" fontWeight={650} sx={{ transform: 'scaleY(0.76)' }}>
            Таны
            <br />
            эзэмших
            <br />
            чадвар
          </Typography>
        </Stack>
      </Stack>
      <Typography
        display={{ sm: 'flex', md: 'none' }}
        paddingTop={10}
        paddingBottom={6}
        fontSize={'32px'}
        lineHeight={'120%'}
        textTransform="uppercase"
        fontWeight={500}
        color="#111111"
        sx={{ transform: 'scaleY(0.76)' }}
      >
        Таны эзэмших чадвар
      </Typography>
      <Stack flex={1} gap="72px" paddingTop={{ sm: '0', md: '120px' }}>
        {skillsData.map((dt, index) => (
          <SkillCard {...dt} key={index} />
        ))}
      </Stack>
    </Stack>
  );
};

const SkillCard = ({ title, desc, image }) => {
  return (
    <AppearOnScroll>
      <Stack gap={4}>
        <Stack
          paddingLeft={2}
          sx={{
            borderLeft: `2px solid`,
            borderImageSource: `linear-gradient(180deg, rgba(26, 199, 74, 0.3) 0%, #1AC74A 100%)`,
            borderImageSlice: 1,
          }}
        >
          <SlideUpOnScroll delay={100}>
            <Typography fontSize={{ lg: '41.6px', md: '31.2px', sm: '26px' }} textTransform="uppercase" fontWeight={500} sx={{ transform: 'scaleY(0.76)' }} color="thirtary">
              {title}
            </Typography>
          </SlideUpOnScroll>
          <Box
            sx={{
              maxWidth: {
                sm: 'calc(100vw - 68px)',
                md: 'auto',
              },
            }}
          >
            <SlideUpByLine globaldelay={200} variant="bodyM" color="#393939" marginTop={'20px'} text={desc} />
          </Box>
        </Stack>

        <Box
          height="296px"
          sx={{
            WebkitMask: {
              sm: [
                'linear-gradient(45deg, transparent 0 0px ,#fff 0) bottom left',
                'linear-gradient( -45deg, transparent 0 24px,#fff 0) bottom right',
                'linear-gradient( 135deg, transparent 0 24px,#fff 0) top left',
                'linear-gradient(-135deg, transparent 0 0,#fff 0) top right',
              ].join(', '),
              md: [
                'linear-gradient(45deg, transparent 0 0px ,#fff 0) bottom left',
                'linear-gradient( -45deg, transparent 0 40px,#fff 0) bottom right',
                'linear-gradient( 135deg, transparent 0 40px,#fff 0) top left',
                'linear-gradient(-135deg, transparent 0 0,#fff 0) top right',
              ].join(', '),
            },
            WebkitMaskSize: `50.1% 50.1%`,
            WebkitMaskRepeat: `no-repeat`,
          }}
        >
          <Image
            alt={image}
            src={image}
            fill
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </Box>
      </Stack>
    </AppearOnScroll>
  );
};

import { RoundedArrowLeftIcon, RoundedArrowRightIcon } from '@assets/icons';
import { BackInUp, InterviewVideo } from '@components';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { useState } from 'react';
import { INTERVIEW_DATA, INTERVIEW_DATA_PROPS } from './data-content';

export const HomeInterviewsSection = () => {
  const [postIndex, setPostIndex] = useState<number>(0);
  const lastPostIndex = INTERVIEW_DATA.length - 1;

  const reducePostIndex = () => {
    setPostIndex((prev) => (prev === 0 ? 0 : prev - 1));
  };
  const increasePostIndex = () => {
    setPostIndex((prev) => (prev === lastPostIndex ? prev : prev + 1));
  };

  return (
    <Stack gap={5}>
      {INTERVIEW_DATA.map((interviewCard, index) => {
        if (index === postIndex) return <InterviewCard key={index} {...interviewCard} />;
      })}
      <Stack gap={1.5} direction="row" width="100%" justifyContent="center">
        <IconButton onClick={reducePostIndex} aria-label="Өмнөх" data-cy="Home-Interview-Left-Icon">
          <RoundedArrowLeftIcon color={postIndex !== lastPostIndex ? 'grey' : 'black'} />
        </IconButton>

        <IconButton onClick={increasePostIndex} aria-label="Дараах" data-cy="Home-Interview-Right-Icon">
          <RoundedArrowRightIcon color={postIndex === lastPostIndex ? 'grey' : 'black'} />
        </IconButton>
      </Stack>
    </Stack>
  );
};

const InterviewCard = (props: INTERVIEW_DATA_PROPS) => {
  const { title, subTitle, description, interviewer, video, videoPoster } = props;

  return (
    <BackInUp duration={0.5}>
      <Stack direction={{ sm: 'column', md: 'row' }} textAlign={{ sm: 'center', md: 'initial' }}>
        <Stack width={{ sm: '100%', md: '50%' }} alignItems={{ sm: 'center', md: 'flex-start' }}>
          <Typography fontSize={{ lg: 62, md: 36, sm: 31 }} lineHeight="120%" textTransform="uppercase" fontWeight={700} sx={{ transform: 'scaleY(0.76)' }}>
            {title}
          </Typography>

          <Box height="2px" width="26.5px" display={{ sm: 'block', md: 'none' }} sx={{ background: '#1AC74A' }} />

          <Typography
            variant="bodyMSemibold"
            paddingLeft="16px"
            sx={{
              borderLeft: `2px solid`,
              borderImageSource: `linear-gradient(180deg, rgba(26, 199, 74, 0.3) 0%, #1AC74A 100%)`,
              borderImageSlice: { sm: 0, md: 1 },
            }}
          >
            {subTitle}
          </Typography>

          <Typography typography={{ sm: 'bodyM', lg: 'displayM' }} marginY={5} sx={{ fontWeight: '400 !important' }} data-cy="Home-Interview-Description">
            {description}
          </Typography>

          {interviewer && (
            <Stack>
              <Image src={interviewer.image} width={93} height={28} alt="amazon" />
              <Typography variant="bodyM" marginTop={1} marginBottom={{ sm: 4, md: 0 }}>
                {interviewer.name}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Box width={{ sm: '100%', md: '50%' }}>
          <InterviewVideo src={video} thumbnail={videoPoster} style={{ width: '100%' }} />
        </Box>
      </Stack>
    </BackInUp>
  );
};

import { VerifiedIcon } from '@assets/icons';
import { SlideUpOnScroll } from '@components';
import { useMediaQuery } from '@hooks';
import { Stack, Typography } from '@mui/material';

export const HomeAboutUsSection = () => {
  const isDesktop = useMediaQuery('lg');

  return (
    <Stack direction={{ sm: 'column', lg: 'row' }} alignItems="center" gap={{ sm: 5, lg: 25 }}>
      <Typography
        lineHeight="100%"
        fontSize={{ lg: '62.4px', md: '36.4px', sm: '31.2px' }}
        textTransform="uppercase"
        fontWeight={{ sm: 500, md: 400 }}
        sx={{ transform: 'scaleY(0.76)' }}
        color={'black'}
      >
        Бидний
        {isDesktop ? <br /> : ' '}
        тухай
      </Typography>

      <Stack
        direction={{ sm: 'column', md: 'row' }}
        gap={{ sm: '64px', md: 0 }}
        flex={3}
        justifyContent="space-between"
        alignItems={{ sm: 'center', md: 'flex-start' }}
        width={{ sm: 'auto', md: '100%', lg: 'auto' }}
      >
        <Infos value="5+" subValue="ЖИЛИЙН ТУРШЛАГА" />
        <Infos value="500+" subValue="ТӨГСӨГЧИД" />

        <Stack alignItems="center">
          <SlideUpOnScroll delay={400}>
            <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Title>9.2</Title>
              <Typography fontSize={'31.2px'} textTransform="uppercase" fontWeight={700} sx={{ transform: 'scaleY(0.76)' }} color="primary.main">
                /10
              </Typography>
            </Stack>
          </SlideUpOnScroll>

          <Typography typography={{ sm: 'buttonS', lg: 'displayS' }} color="text.subhead">
            СЭТГЭЛ ХАНАМЖ
          </Typography>

          <Stack direction="row" gap={1} mt={1}>
            <Typography variant="bodyS" color="text.subhead">
              NPS
            </Typography>
            <VerifiedIcon />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

const Title = ({ children, color, fontWeight }: { children: (JSX.Element | string)[] | string; color?: string; fontWeight?: number }) => (
  <Typography lineHeight="100%" fontSize="62.4px" textTransform="uppercase" fontWeight={fontWeight || 700} sx={{ transform: 'scaleY(0.76)' }} color={color || 'primary.main'}>
    {children}
  </Typography>
);

const Infos = ({ value, subValue }: { value: string; subValue: string }) => {
  return (
    <Stack alignItems="center">
      <SlideUpOnScroll delay={300}>
        <Title>{value}</Title>
      </SlideUpOnScroll>

      <Typography typography={{ sm: 'buttonS', lg: 'displayS' }} color="text.subhead">
        {subValue}
      </Typography>
    </Stack>
  );
};
